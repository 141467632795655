window.enlarge = function (options = {}) {
  return {
    download: 'download' in options ? options.download : undefined,
    url: 'url' in options ? options.url : undefined,
    download_url: 'download_url' in options ? options.download_url : undefined,
    alt: 'alt' in options ? options.alt : '',
    show() {
      document.getElementById('image_modal_img').setAttribute('src', this.url)
      document.getElementById('image_modal_img').setAttribute('alt', this.alt)
      document.getElementById('image_modal_download_url').setAttribute('href', this.download_url)
      Alpine.store('imageModal').show()
    },
  }
}
