window.confirm = function (options = {}) {
  return {
    form: 'form' in options ? options.form : undefined,
    title: 'title' in options ? options.title : 'Are you sure?',
    text: 'text' in options ? options.text : 'You won\'t be able to revert this!',
    cancelButton: 'cancelButton' in options ? options.cancelButton : 'Cancel',
    confirmButton: 'confirmButton' in options ? options.confirmButton : 'Delete',
    type: 'type' in options ? options.type : 'danger',
    show(form_id = undefined) {
      const id = showType(this.type)
      document.getElementById('confirm_modal_title').textContent = this.title
      document.getElementById('confirm_modal_text').textContent = this.text
      document.getElementById('confirm_modal_cancel_button').textContent = this.cancelButton
      document.getElementById('confirm_modal_icon_background').style.backgroundColor = this.type
      document.getElementById(id).textContent = this.confirmButton
      document.getElementById(id).onclick = () => {
        if (form_id) {
          document.getElementById(form_id).submit()
        }
      }
      Alpine.store('confirmModal').show()
    },
  }
}

function showType(type) {
  const buttons = {
    success: 'confirm_modal_confirm_button_success',
    danger: 'confirm_modal_confirm_button_danger',
    warning: 'confirm_modal_confirm_button_warning',
    info: 'confirm_modal_confirm_button_info',
    default: 'confirm_modal_confirm_button',
  }

  if (!(type in buttons)) {
    type = 'default'
  }

  const { [type]: current, ...rest } = buttons

  for (const button in rest) {
    document.getElementById(rest[button]).style.display = 'none'
  }
  document.getElementById(current).style.display = 'block'

  return current
}
