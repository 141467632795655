window.infiniteScroll = function infiniteScroll(initialUrl, value = undefined) {
  return {
    accessor: 'name',
    triggerElement: null,
    search: '',
    cache: {
      '': {
        page: 1,
        lastPage: null,
        items: [],
        links: {
          next: initialUrl,
        },
        state: 'idle',
      },
    },
    items: [],
    value: value ?? '',
    itemsPerPage: 10,
    observer: null,
    isObserverPolyfilled: false,
    init(elementId, accessor) {
      const ctx = this
      this.accessor = accessor
      this.triggerElement = document.querySelector(elementId || '#infinite-scroll-trigger')

      // Check if browser can use IntersectionObserver which is waaaay more performant
      if (!('IntersectionObserver' in window)
        || !('IntersectionObserverEntry' in window)
        || !('isIntersecting' in window.IntersectionObserverEntry.prototype)
        || !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        // Loading polyfill since IntersectionObserver is not available
        this.isObserverPolyfilled = true

        // Storing function in window so we can wipe it when reached last page
        window.alpineInfiniteScroll = {
          scrollFunc() {
            const position = ctx.triggerElement.getBoundingClientRect()

            if (position.top < window.innerHeight && position.bottom >= 0) {
              ctx.getItems()
            }
          },
        }

        window.addEventListener('scroll', window.alpineInfiniteScroll.scrollFunc)
      } else {
        // We can access IntersectionObserver
        this.observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting === true) {
            ctx.getItems()
          }
        }, { threshold: [0] })

        this.observer.observe(this.triggerElement)
      }

      this.getItems()
    },
    onChange(event) {
      this.search = event.target.value
      this.getItems()
    },
    getItems() {
      const search = this.search

      if (this.cache[search] === undefined) {
        this.cache[search] = {
          page: 1,
          lastPage: null,
          items: [],
          links: {
            next: initialUrl + (initialUrl.includes('?') ? '&search=' : '?search=') + search,
          },
          state: 'idle',
        }
      } else {
        this.items = this.cache[this.search].items
      }

      if (this.cache[search].links.next === null) {
        this.items = this.cache[this.search].items
        return
      }

      if (this.cache[search].state === 'loading') {
        console.log('Already loading')
        return
      }

      this.cache[search].state = 'loading'

      // Fetch next page
      console.log(this.cache[search].links.next)
      if (!this.cache[search].links.next) {
        this.cache[search].state = 'idle'
        return
      }

      fetch(this.cache[search].links.next)
        .then(response => response.json())
        .then((data) => {
          // oeps, looks terrible
          this.cache[search].links.next = data.pagination?.links?.next ?? data.next_page_url ?? data.links?.next
          this.cache[search].items = this.cache[search].items.concat(data.data)
          this.cache[search].lastPage = data.pagination?.page_total ?? data.last_page ?? data.links?.last_page
          this.cache[search].page = data.pagination?.page ?? data.current_page ?? data.meta.current_page
        })
        .finally(() => {
          this.cache[search].state = 'idle'

          // Update pointer with global search string
          this.items = this.cache[this.search].items
        })
        .catch(error => console.error(error))
    },
  }
}
