window.progressBar = function (options = {
    channel: '',
    event: '',
    errorEvent: '',
}) {
  return {
    progress: 0, // 0 - 100
    extra: {},
    state: 'Pending',
    animationSpeed: 2,
    init() {
      console.log(`Listening to ${options.channel}.${options.event}`)
      Echo.private(options.channel)
        .listen(options.event, (e) => {
          this.extra = e
          this.state = e.status
          console.log(e)
          this.progress = e.processed / e.total * 100

          if (e.status === 'Completed') {
            this.animationSpeed = 0.2
          }
          console.log(e.processed / e.total * 100)
        })
        .listen(options.errorEvent, (e) => {
          console.log(e)
          this.state = 'Failed'
          this.extra.error = e.message
        })
    },
  }
}
