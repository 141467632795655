window.dataTable = function (options = {}) {
  return {
    name: 'name' in options ? options.name : undefined,
    items: 'data' in options ? options.data : [],
    searchInput: '',
    pages: [],
    offset: 'perPage' in options ? options.perPage : 10,
    pagination: {
      total: 0,
      lastPage:
                'data' in options
                  ? Math.ceil(options.data.length / options.perPage)
                  : 1,
      perPage: 'perPage' in options ? options.perPage : 10,
      currentPage: 1,
      from: 1,
      to: 'perPage' in options ? options.perPage : 10,
    },
    currentPage: 1,
    sorted: {
      field: 'sortField' in options ? options.sortField : 'id',
      rule: 'sortDir' in options ? options.sortDir : 'asc',
      attribute: false,
    },
    saveState: 'saveState' in options ? options.saveState : false,
    searchable: 'searchable' in options ? options.searchable : [],
    serverside: 'serverside' in options ? options.serverside : false,
    url: 'url' in options ? options.url : '',
    exportUrl: 'exportUrl' in options ? options.exportUrl : '',
    filters: 'filters' in options ? options.filters : {},
    livereload: 'livereload' in options ? options.livereload : false,
    interval: 'interval' in options ? options.interval : 10000,
    initData() {
      if (this.serverside) {
        this.fetchData()
        if (this.livereload) {
          this.setInterval()
        }
      } else {
        this.items = this.items.sort(
          this.compareOnKey(this.sorted.field, this.sorted.rule),
        )
      }
    },
    search(value) {
      const url = new URL(window.location.href)

      if (value === '') {
        url.searchParams.delete('search')
      } else {
        url.searchParams.set('search', value)
      }

      if (url.searchParams.has('page')) {
        url.searchParams.delete('page')
      }

      history.pushAndNotify(url.toString())
    },
    toggleSort(field, attribute = false) {
      const url = new URL(window.location.href)

      const dir = url.searchParams.get('dir')
      const sort = url.searchParams.get('sort')

      url.searchParams.set('sort', field)
      url.searchParams.delete('page')
      url.searchParams.set('attr', attribute)

      if (sort === field && dir === 'desc') {
        url.searchParams.delete('dir')
        url.searchParams.delete('sort')
        url.searchParams.delete('attr')
      } else if (sort === field) {
        url.searchParams.set('dir', dir === 'asc' ? 'desc' : 'asc')
      } else {
        url.searchParams.set('dir', 'asc')
      }

      history.pushAndNotify(url.toString())
    },
    changeView(e) {
      const url = new URL(window.location.href)

      url.searchParams.set('pp', e.target.value)

      if (url.searchParams.has('page')) {
        url.searchParams.delete('page')
      }

      if (e.target.value === '' || e.target.value === '10') {
        url.searchParams.delete('pp')
      }
      history.pushAndNotify(url.toString())
    },
    changeFilter(e) {
      const url = new URL(window.location.href)
      if (e.target.value === '') {
        url.searchParams.delete(`filter[${e.target.name}]`)
        this.filters = { ...this.filters, [e.target.name]: undefined }
      } else {
        url.searchParams.set(`filter[${e.target.name}]`, e.target.value)
      }

      if (url.searchParams.has('page')) {
        url.searchParams.delete('page')
      }
      history.pushAndNotify(url.toString())
    },
    hasPages() {
      return this.pages.length > 0
    },
    setPage(page) {
      const url = new URL(window.location.href)
      url.searchParams.set('page', page)
      history.pushAndNotify(url.toString())
    },
    nextPage() {
      console.log('next', this.pagination.currentPage, this.pagination.lastPage)
      const url = new URL(window.location.href)
      if (this.pagination.currentPage < this.pagination.lastPage) {
        url.searchParams.set('page', (Number.parseInt(this.pagination.currentPage) + 1).toString())
        history.pushAndNotify(url.toString())
      }
    },
    prevPage() {
      console.log('prev', this.pagination.currentPage, this.pagination.lastPage)
      const url = new URL(window.location.href)
      if (this.pagination.currentPage > 2) {
        url.searchParams.set('page', (Number.parseInt(this.pagination.currentPage) - 1).toString())
        history.pushAndNotify(url.toString())
      }
      if (this.pagination.currentPage === 2) {
        url.searchParams.delete('page')
        history.pushAndNotify(url.toString())
      }
    },
    setInterval() {
      setInterval(() => {
        this.fetchData()
      }, this.interval)
    },
    fetchData() {
      // console.log('Fetching data', this.filters);
      const url = new URL(this.url)

      const qp = new URLSearchParams(window.location.search)

      const params = {
        searchable: this.searchable,
        filters: { ...this.filters },
      }

      if (qp.has('page')) {
        params.page = qp.get('page')
      }

      if (qp.has('pp')) {
        params.perPage = qp.get('pp')
      }

      if (qp.has('sort')) {
        params.sort = {
          field: qp.get('sort'),
          rule: qp.has('dir') ? qp.get('dir') : 'asc',
          attribute: qp.has('attr') ? qp.get('attr') : false,
        }
      }

      this.sorted = params.sort

      qp.forEach((value, key) => {
        if (key.startsWith('filter[')) {
          console.log('found filter', key, value)
          const filter = key.replace('filter[', '').replace(']', '')
          params.filters[filter] = value
        }
      })

      if (qp.has('search')) {
        params.search = qp.get('search')
      }

      Object.keys(params).forEach(key => url.searchParams.append(key, typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key]))
      axios
        .get(url.href)
        .then((response) => {
          const data = response.data
          this.items = data.data
          this.pagination.currentPage = data.meta.current_page
          this.pagination.lastPage = data.meta.last_page
          this.pagination.total = data.meta.total
          this.pagination.from = data.meta.from
          this.pagination.to = data.meta.to
          this.lastPage = Math.ceil(
            data.meta.total / data.meta.per_page,
          )
          this.pages = data.meta.links
        })
    },
    exportData(format) {
      const qp = new URLSearchParams(window.location.search)
      let sort = {}
      if (qp.has('sort')) {
        sort = {
          field: qp.get('sort'),
          rule: qp.has('dir') ? qp.get('dir') : 'asc',
          attribute: qp.has('attr') ? qp.get('attr') : false,
        }
      }
      const filters = {}
      qp.forEach((value, key) => {
        if (key.startsWith('filter[')) {
          const filter = key.replace('filter[', '').replace(']', '')
          filters[filter] = value
        }
      })

      let search = ''
      if (qp.has('search')) {
        search = qp.get('search')
      }

      let url = `${this.exportUrl}?format=${format}&sort=${JSON.stringify(sort)}&search=${search}&filters=${JSON.stringify(filters)}`

      for (const [key, value] of Object.entries(this.searchable)) {
        url += `&searchable[${key}]=${value}`
      }

      window.open(url, '_blank')
    },
  }
}
