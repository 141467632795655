window.repeater = function (options = {}) {
  return {
    fields: 'fields' in options ? options.fields : {},
    rows: 'rows' in options ? options.rows : [],
    add() {
      this.rows.push(Object.assign({}, this.fields))
    },
    remove(index) {
      this.rows.splice(index, 1)
    },
  }
}
